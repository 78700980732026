import "./src/assets/scss/main.scss";

const redirectToPostsPage = () => {
  const currentUrl = window.location.href;
  const pattern = /^(http|https):\/\/(localhost:8000|xenojoshua.com|127.0.0.1:8900)\/(\d{4})\/(\d{2})\/(.+)/;
  const match = currentUrl.match(pattern);

  if (match === null) {
    return; // non-match
  }

  const [, protocol, host, year, month, slug] = match;
  const redirect = `${protocol}://${host}/posts/${year}/${month}/${slug}`;
  window.location.replace(redirect);
};

redirectToPostsPage();
